<template>
  <div class="card py-3 px-4 card-citmer cardRadius mb-5">
    <div class="d-flex">
      <div class="titles col-10">Migración de pacientes</div>
    </div>
    <hr />
    <div class="row align-items-center mb-5">
      <div class="col-4">
        <label class="form-label">NEC</label>
        <b-form-input
          v-model="nec"
          type="number"
          :state="!$v.nec.$error && !$v.nec.$invalid" />
      </div>
      <div class="col-4">
        <label class="form-label">Filiación</label>
        <b-form-input
          v-model="filiation_num"
          type="number"
          :state="!$v.filiation_num.$error && !$v.filiation_num.$invalid" />
      </div>
      <div class="d-flex justify-content-evenly align-items-center col-4 pt-4">
        <button
          @click="getMigrationLogs"
          class="
            d-flex
            justify-content-center
            align-items-center
            btn btn-primary
          "
          :disabled="$v.$invalid">
          <h6 class="p-0 m-0">Logs</h6>
          <font-awesome-icon
            class="ms-3 fs-5"
            :icon="['fas', 'file-circle-exclamation']" />
        </button>
        <button
          @click="migratePatient"
          class="
            d-flex
            justify-content-center
            align-items-center
            btn btn-primary
          "
          :disabled="$v.$invalid">
          <h6 class="p-0 m-0">Migrar</h6>
          <font-awesome-icon class="ms-3 fs-5" :icon="['fas', 'save']" />
        </button>
      </div>
    </div>
    <b-table
      ref="migrationTable"
      :table-class="'patientsHeadClass'"
      responsive
      :items="migrationLogs"
      :fields="fieldsTable"
      v-if="migrationLogs"
      @row-clicked="toggleDetails"
      striped
      small
      borderless
      empty-text="No hay resultados"
      show-empty>
      <template v-slot:cell(name)="row">
        <span v-text="row.item.logs.split('\n')[2].slice(21)"></span>
      </template>
      <template v-slot:cell(created_at)="row">
        <span
          v-text="
            $moment(row.item.created_at).format('DD-MM-YYYY HH:mm')
          "></span>
      </template>
      <template v-slot:cell(error)="row">
        <button class="btn btn-outline-success" v-if="!row.item.error">
          Migrado</button
        ><button class="btn btn-outline-danger" v-else>Error</button>
      </template>
      <template #row-details="row">
        <div
          class="d-flex justify-content-between align-items-center"
          v-for="(log, index) in row.item.logs.split('\n')"
          :key="log">
          <div>
            <h6 class="p-0 m-0" v-text="log"></h6>
          </div>
          <div class="col">
            <hr />
          </div>
          <div class="d-flex justify-content-center align-items-center">
            <h4 class="text-center p-0 m-0">
              <font-awesome-icon
                v-if="
                  !row.item.error ||
                  (row.item.error &&
                    index < row.item.logs.split('\n').length - 1)
                "
                class="text-success svg-inline--fa fa-search fa-w-16"
                :icon="['fas', 'circle-check']" />
              <font-awesome-icon
                v-else
                class="text-danger svg-inline--fa fa-search fa-w-16"
                :icon="['fas', 'circle-exclamation']" />
            </h4>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
// @ is an alias to /src
import { required } from "vuelidate/lib/validators";

export default {
  name: "MigrationCard",
  data() {
    return {
      nec: null,
      filiation_num: null,
      fieldsTable: [
        { class: "text-center", key: "name", label: "Nombre" },
        { class: "text-center", key: "filiation_num", label: "Filiación" },
        { class: "text-center", key: "nec", label: "NEC" },
        { class: "text-center", key: "created_at", label: "Fecha" },
        { class: "text-center mxw-12", key: "error", label: "Estado" },
      ],
      migrationLogs: null,
    };
  },
  validations: {
    nec: {
      required,
    },
    filiation_num: {
      required,
    },
  },
  methods: {
    isFormDataValid() {
      this.$v.$reset();
      this.$v.$touch();
      return !this.$v.$error && !this.$v.$invalid;
    },
    toggleDetails(item) {
      // eslint-disable-next-line no-underscore-dangle
      if (item._showDetails) {
        this.$set(item, "_showDetails", false);
      } else {
        this.$nextTick(() => {
          this.$set(item, "_showDetails", true);
        });
      }
    },
    getMigrationLogs() {
      const payload = {
        nec: this.nec,
        filiation_num: this.filiation_num,
        // nec: 4246,
        // filiation_num: 80033,
      };
      this.$api.settings.getMigrationLogs(payload).then((res) => {
        this.migrationLogs = res.data.migration_logs;
      });
    },
    migratePatient() {
      const payload = {
        nec: this.nec,
        filiation_num: this.filiation_num,
        // nec: 4246,
        // filiation_num: 80033,
      };
      this.$api.settings.migratePatient(payload).then((res) => {
        this.migrationLogs = [res.data.migration_log];
      });
    },
  },
};
</script>
