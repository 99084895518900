<template>
  <div class="col-11">
    <MigrationCardVue
      @getCalendarConfig="getCalendarConfig"
      :calendarConfig="calendarConfig"
      class="mb-5" />
  </div>
</template>

<script>
// @ is an alias to /src
/* eslint-disable import/no-unresolved */
/* eslint-disable prefer-destructuring */
import MigrationCardVue from "@/components/migrations/MigrationCard.vue";

export default {
  name: "Migrations",
  components: {
    MigrationCardVue,
  },
  data() {
    return {
      calendarConfig: {},
    };
  },
  mounted() {
    this.getCalendarConfig(this.clinicId);
  },
  watch: {
    clinicId(newId) {
      this.getCalendarConfig(newId);
    },
  },
  methods: {
    getCalendarConfig(id) {
      this.$api.calendar.getConfig({ clinic_id: id }).then((res) => {
        this.calendarConfig = res.data;
      });
    },
    getSchedules() {
      this.$refs.SettingsServices.getSchedules();
    },
    openPatientModal() {
      this.$store.getters.getModal("patientModal").show();
    },
  },
  computed: {
    clinicId() {
      return parseInt(this.$store.getters.getClinic, 10);
    },
  },
};
</script>
